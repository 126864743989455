import axios from 'axios';
import { SimplyCard, Card, Manager, Picture } from '@/interfaces/card';
import { SimplyUser, User } from '@/interfaces/user';
import { EditedProject, Project } from '@/interfaces/projects';
import { convertToLabelledValueList, toCamelCaseKey, toSnakeCaseKey, downloadFile } from '@/utils';
import { ExportFormat } from './interfaces/enums';
import { Analysis, UpdateStatusAnalysis, UploadAnalysis } from './interfaces/analysis';
import { UploadResponse } from '@/interfaces/response';


const api = {
  async getConfiguration(): Promise<Record<string, any>> {
    const response = await axios.get<{ data: Record<string, any> }>('/configuration');
    return response.data;
  },
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post('/login/access-token', params);
  },
  async getMe() {
    return axios.get<User>('/users/me')
      .then((response) => {
        return toCamelCaseKey(response.data) as User;
      });;
  },
  // Talent cards
  async getTalentCards() {
    const response = await axios.get('/talents');

    if (response.status === 206) {
      // Afficher un message d'avertissement
      return toCamelCaseKey(response.data.talents) as SimplyCard[];
    } else {
      return toCamelCaseKey(response.data) as SimplyCard[];
    }
  },
  async getTalentCard(id: string) {
    return axios.get(`/talents/${id}`)
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },
  async updateTalentCard(card: Card) {
    return axios.put(`/talents/${card.id}`, toSnakeCaseKey(card))
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },
  async updateTalentCards(cards: SimplyCard[]) {
    return axios.put(`/talents`, toSnakeCaseKey(cards))
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },
  async createTalentCard(card: Card) {
    return axios.post(`/talents`, toSnakeCaseKey(card))
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },
  async exportTalentCard(cardId: string = '', filename: string) {
    return axios.get(`/exports/${cardId}`, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      downloadFile(response.data, filename, ExportFormat.Powerpoint);
    });
  },
  async checkExportPowerpointFileAvailability(exportId: string) {
    return axios.get(`/exports/pending/${exportId}`)
    .then((response) => {
      return toCamelCaseKey(response.data);
    });
  },
  async exportTalentCardsInPowerpoint(filters: any = {}) {
    return axios.get(`/exports`,{
      params: filters,
    })
    .then((response) => {
      return toCamelCaseKey(response.data);
    });
  },
  async exportPowerpointFile(exportId: string, filename: string) {
    return axios.get(`/exports/processed/${exportId}`, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      downloadFile(response.data, filename, ExportFormat.Powerpoint);
    });
  },
  async exportTalentCardsInExcel(filters: any = {}) {
    return axios.get('/exports_table',{
      params: filters,
    })
    .then((response) => {
      return toCamelCaseKey(response.data);
    });
  },
  async checkExportExcelFileAvailability(exportId: string) {
    return axios.get(`/exports_table/pending/${exportId}`)
    .then((response) => {
      return toCamelCaseKey(response.data);
    });
  },
  async exportExcelFile(exportId: string, filename: string) {
    return axios.get(`/exports_table/processed/${exportId}`, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      downloadFile(response.data, filename, ExportFormat.Excel);
    });
  },
  async getManagers() {
    return axios.get('/managers')
      .then((response) => {
        return toCamelCaseKey(response.data) as Manager[];
      });
  },
  async uploadPhoto(id:string, file: File) {
    const formData = new FormData();
    formData.append("upload_file", file);
    return axios.post(`/talents/${id}/photo`, formData, {
      headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        return toCamelCaseKey(response.data) as Picture;
      });
  },
  async deletePhoto(id: string) {
    return axios.delete(`/talents/${id}/photo`)
      .then((response) => {
        return response.data;
      });
  },
  async deleteTalentCard(id: string) {
    return axios.delete(`/talents/${id}`)
    .then((response) => {
      return response.data;
    });
  },

  // Users
  async getUsers() {
    return axios.get('/users')
      .then((response) => {
        return toCamelCaseKey(response.data) as SimplyUser[];
      });
  },

  async getUser(id: string) {
    return axios.get(`/users/${id}`)
      .then((response) => {
        return toCamelCaseKey(response.data) as User;
      });
  },

  async createUser(user: User) {
    return axios.post(`/users`, toSnakeCaseKey(user))
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },

  async updateUser(user: User) {
    return axios.put(`/users/${user.id}`, toSnakeCaseKey(user))
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },

  async deleteUser(id: string) {
    return axios.delete(`/users/${id}`)
    .then((response) => {
      return response.data;
    });
  },

  // Projects
  async getProjects() {
    return axios.get('/projects')
      .then((response) => {
        return toCamelCaseKey(response.data) as Project[];
      });
  },

  async getProject(id: string) {
    return axios.get(`/projects/${id}`)
      .then((response) => {
       return toCamelCaseKey(response.data) as Project;
      });
  },

  async createProject(project: Project) {
    return axios.post(`/projects`, toSnakeCaseKey(project))
      .then((response) => {
        return toCamelCaseKey(response.data) as Project;
      });
  },
  async deleteProject(id: string) {
    return axios.delete(`/projects/${id}`)
    .then((response) => {
      return response.data;
    });
  },

  async updateProject(project: EditedProject) {
    return axios.put(`/projects/${project.id}`, toSnakeCaseKey(project))
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },

  // Analysis
  async getAnalyses() {
    return axios.get('/analysis')
      .then((response) => {
        return toCamelCaseKey(response.data) as Analysis[];
      });
  },

  async getAnalysis(id: string) {
    return axios.get(`/analysis/${id}`)
      .then((response) => {
        return toCamelCaseKey(response.data) as Analysis;
      });
  },

  async createAnalysis(analyse: Analysis) {
    return axios.post(`/analysis`, toSnakeCaseKey(analyse))
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },

  async importAnalysis(uploadInfo: UploadAnalysis): Promise<UploadResponse> {
    const formData = new FormData();
    formData.append('upload_file', uploadInfo.file);
    const response = await axios.post(
      `/analysis/${uploadInfo.id}/${uploadInfo.format}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },

    );
    return toCamelCaseKey(response.data);
  },

  async updateAnalysisStatus(updateStatusAnalysis: UpdateStatusAnalysis): Promise<Analysis> {
    return axios.put(`/analysis/${updateStatusAnalysis.id}`, toSnakeCaseKey(updateStatusAnalysis))
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },


  async getNudges() {
    return axios.get(`/nudges`)
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },

  async downloadData(filters: any = {}) {
    return axios.get(`/exports_table`, {
      params: filters,
    })
      .then((response) => {
        return toCamelCaseKey(response.data);
      });
  },

  async downloadReportsExcel(id: string, filename: string) {
    return axios.get(`/analysis/${id}/excel`, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        downloadFile(response.data, filename, ExportFormat.Excel);
      });
  },

  async downloadReportsPDF(id: string, filename: string) {
    return axios.get(`/analysis/${id}/pdf`, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        downloadFile(response.data, filename, ExportFormat.PDF);
      });
  },


  // Utils
  async getNationalities(){
    return axios.get('/nationalities')
      .then((response) => {
        return convertToLabelledValueList(response.data);
      });
  },

  async getLanguages(){
    return axios.get('/languages')
      .then((response) => {
        return convertToLabelledValueList(response.data);
      });
  },

  async getCountries(){
    return axios.get('/countries')
      .then((response) => {
        return convertToLabelledValueList(response.data);
      });
  },


};

export default api;
